import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { Home } from "./components/Home"
import { MaintenanceMode } from "./components/MaintenanceMode"
import { Playground } from "./components/Playground"


export const App = ({ mobileMode, tabletMode }) => {
  const maintenanceMode = Boolean(process.env.REACT_APP_MAINTENANCE_MODE === "true" && process.env.REACT_APP_DEV_MODE !== "dev")
  const dt = process.env.REACT_APP_DT

  return (
    <BrowserRouter>
      <Header tabletMode={tabletMode} mobileMode={mobileMode} maintenanceMode={maintenanceMode} />
      <Routes>
        <Route exact path="/" element={maintenanceMode ? <MaintenanceMode dt={dt} /> : <Home tabletMode={tabletMode} mobileMode={mobileMode} />} />
        <Route path="playground">
          <Route path="" element={<Playground tabletMode={tabletMode} mobileMode={mobileMode} />} />
        </Route>
      </Routes>
      <Footer tabletMode={tabletMode} mobileMode={mobileMode} />
    </BrowserRouter >
  )
}
