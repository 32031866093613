import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { GoInfo } from "react-icons/go"
import { handleGtag } from "./_utils/gtag"
import { usecaseList, modelList, agentFormations } from "./_common"
import { ModelButton } from "./LLM"


export const Home = ({ tabletMode, mobileMode }) => {
  const navigate = useNavigate()
  const [displayExp, setDisplayExp] = useState(null)
  const [goalId, setGoalId] = useState(null)
  const [selectedFormationId, setSelectedFormationId] = useState(null)
  const [selectedModelId, setSelectedModelId] = useState(null)
  const [modelStartId, setModelStartId] = useState(0)
  const defaultNum = Math.floor(window.innerWidth / 300)

  const isSmallScreen = mobileMode || tabletMode
  const containerStyle = { maxWidth: mobileMode ? "100%" : tabletMode ? "700px" : "1200px", display: "flex", flexDirection: "column", alignItems: "center", minHeight: mobileMode ? undefined : "700px" }

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href="https://versi0n.io" />
        <title>Home | version</title>
      </Helmet>
      {selectedFormationId === null &&
        <div className="container content" style={{ maxHeight: isSmallScreen ? undefined : "700px", padding: isSmallScreen ? "50px 20px" : "100px 50px", ...containerStyle }}>

          <h1 style={{ lineHeight: 1.5, fontWeight: 700, padding: "0 0 10px 0" }} itemProp="description">
            Form agents
          </h1>

          <div style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", alignItems: isSmallScreen ? "center" : undefined, height: isSmallScreen ? undefined : "200px" }}>
            {agentFormations.map((item, i) => {
              const { img, title, usage, usecase } = item
              return (
                <div key={i} style={{ dispaly: "flex", flexDirection: "column", alignItems: "center", minWidth: "240px", maxWidth: "240px", padding: "10px", marginBottom: "16px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <label className="radio">
                      <input type="radio" checked={Boolean(selectedFormationId === agentFormations.indexOf(item))} onClick={e => { setSelectedFormationId(agentFormations.indexOf(item)) }} />
                      <span style={{ fontWeight: 700 }}>&nbsp;{title}</span>
                    </label>
                  </div>
                  {img && <img src={img} alt={title} width={120} style={{ maxHeight: "120px", minHeight: "120px" }} />}
                  <p>{usage}</p>
                  {usecase && <p><span style={{ fontWeight: 700 }}>i.e., </span>{usecase}</p>}
                </div>
              )
            })}
          </div>
        </div>
      }

      {selectedFormationId !== null &&
        <div className="container content" style={{ maxHeight: isSmallScreen ? undefined : "700px", padding: isSmallScreen ? "50px 20px" : "100px 50px", ...containerStyle }}>

          <h1 style={{ lineHeight: 1.5, fontWeight: 700, padding: "0 0 10px 0" }} itemProp="description">
            Select main model
          </h1>

          <div style={{ display: "flex", flexWrap: "wrap", flexDirection: isSmallScreen ? "column" : "row", alignItems: isSmallScreen ? "center" : undefined, height: isSmallScreen ? undefined : "200px" }}>
            {modelList
              .sort((a, b) => Number(b.isNew) - Number(a.isNew))
              // .filter(item => (modelList.indexOf(item) >= modelStartId && modelList.indexOf(item) < Number(modelStartId) + defaultNum))
              .map((item, i) =>
                <ModelButton
                  key={i}
                  id={modelList.indexOf(item)}
                  model={item}
                  selectedModelId={selectedModelId}
                  setSelectedModelId={setSelectedModelId}
                  selectedFormationId={selectedFormationId}
                />
              )
            }
          </div>
        </div>
      }

    </HelmetProvider>
  )
}
