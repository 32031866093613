import { useState } from "react"
import { useLocation } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { ModelIndex } from "./LLM"
import { BackToPrevPage, Modal } from "./_utils/Elements"
import { handleGtag } from "./_utils/gtag"
import { usecaseList, modelList, agentFormations } from "./_common"

//! UPDATEME - add a credit per day, for retention - start with dummy contact info, add improve x2 - chat with user

export const Playground = ({ tabletMode, mobileMode }) => {
  const mainContainerStyleProps = { width: mobileMode ? "100%" : tabletMode ? "500px" : "800px", minWidth: mobileMode ? "100%" : tabletMode ? "500px" : "640px", maxWidth: mobileMode ? "100%" : tabletMode ? "500px" : "840px", minHeight: mobileMode ? "100vh" : undefined, paddingBottom: "100px" }

  const location = useLocation()
  const [activeId, setActiveId] = useState(0)

  // selected formation
  const selectedFormationId = location.state && location.state.selectedFormationId !== undefined
    ? location.state.selectedFormationId
    : null

  // base model
  const initModelId = location.state && location.state.modelId !== undefined && location.state.modelId !== null
    ? location.state.modelId
    : null
  const [selectedModelId, setSelectedModelId] = useState(initModelId)

  // destination service, data pipeline
  const [dataPipeline, setDataPipeline] = useState(null)
  const [destination, setDestination] = useState(null)

  // natural language request - take 
  const [naturalLangInput, setNaturalLangInput] = useState(null)

  // fixed pre-requist conditions: llm, natural lang input, goal, destination service
  const [fixed, setFixed] = useState(false)

  // message value to be selected
  const [selectedValue, setSelectedValue] = useState(null)


  // // handle daily quota: 3
  // let quota = useRef(0)
  // console.log(window.localStorage)


  // handle cta
  const [displayAuthModal, setDisplayAuthModal] = useState(false)
  const [displayAccountConnectionModal, setDisplayAccountConnectionModal] = useState(false)


  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href="https://versi0n.io/playground" />
        <title>Playground | version</title>
      </Helmet>

      <hr style={{ margin: "5px 0 0", minWidth: "100%", border: "0.3px solid #fafafa", height: "0.5px" }} />

      <h1 style={{ display: "none" }}>Playground</h1>

      <div style={{ minWidth: "100%", display: "flex", flexDirection: mobileMode ? "column" : "row" }}>

        <div className="Playground-side-menu">
          <BackToPrevPage style={{ fontSize: "12px" }} pageName={"home"} />

          <div style={{ padding: mobileMode || tabletMode ? "5px" : "10px", marginTop: "10px" }}>
            <p style={{ fontWeight: 700 }}>Formation</p>
            {selectedFormationId !== null
              ?
              <div>
                <p>{agentFormations[selectedFormationId]["title"]}</p>
                {agentFormations[selectedFormationId]["img"] &&
                  <img src={agentFormations[selectedFormationId]["img"]} alt={agentFormations[selectedFormationId]["title"]} width={120} />
                }
              </div>
              :
              <p>Automation</p>
            }

          </div>

          <div style={{ padding: mobileMode || tabletMode ? "5px" : "10px", marginTop: "10px" }}>
            <p style={{ fontWeight: 700 }}>Main model</p>
            <ModelIndex
              styleProps={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              selectedModelId={selectedModelId}
              setSelectedModelId={setSelectedModelId}
            />
          </div>
        </div>

        <div className="container" style={{ ...mainContainerStyleProps }}>

          <div className="container Playground-sub-container">

          </div>

          <div className="container Playground-sub-container">
            <p>Specific request if any. We'll run analysis later.</p>
            {fixed === true
              ?
              <p>{naturalLangInput}</p>
              :
              <textarea
                className="textarea"
                onChange={e => setNaturalLangInput(e.target.value)}
                placeholder="Outbound objective, KPI, product overview, target audience etc."
              />
            }
          </div>


        </div>

      </div>

    </HelmetProvider >
  )
}