// adding items to be used across multiple components/pages
export const demoUrl = "https://business.linkedin.com/"

export const agentFormations = [
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893140/pj_m_agents/tglrxoiuv7kk7nzvpe1z.jpg",
    title: "Solo Agent",
    usage: "A single agent handles a task using their own tools, knowledge, and memory.",
    usecase: "An email agent draft promo messages for the given audience.",
  },
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893141/pj_m_agents/hxngdvnn5b5qdxo0ayl5.jpg",
    title: "Supervising",
    usage: "A leader agent gives directions to the subordinates while sharing its knowledge and memory.",
    usecase: "The leader agent strategize outbound campaign plans, and assign components to each subordinate agent or network.",
  },
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893142/pj_m_agents/kyc6neg8m6keyizszcpi.jpg",
    title: "Network",
    usage: "Multiple agents handle tasks synchronically while sharing knowledge and memory among the members.",
    usecase: "An email agent and social media agent share the product knowledge and deploy multi-channel outbound campaign together."
  },
  {
    img: "https://res.cloudinary.com/dfeirxlea/image/upload/v1737893139/pj_m_agents/hzpchlcpnpwxwaapu1hr.jpg",
    title: "Random",
    usage: "A single agent handles tasks while asking help for other agents without sharing its knowledge or memory.",
    usecase: "An email agent drafts promo messages for the given audience, asking insights on tones from other email agents which oversee other audience clusters.",
  },
  {
    img: null,
    title: "Automation",
    usage: "I don't have clear plan for now. Let the agent suggest the best formation.",
  }
]

export const usecaseList = [
  {
    title: "Lead Generation",
    exp: "Attract qualified leads with social media display ads.",
    expShort: "Attract qualified leads with social media display ads.",
    items: [
      { title: "Facebook ad", exp: "Draft competitive message for facebook ads", },
      { title: "Referral", exp: "Launch a referral campaign with competitive messages", },
      { title: "Exclusive offer", exp: "Highlight discount, coupon, other exclusive offers", },
      { title: "Custom", exp: "Build your own", }
    ]
  },
  {
    title: "Upselling / Cross-Selling",
    exp: "Analyze purchase history to identify potential motivations, then recommend complementary products.",
    expShort: "Identify potential motivations and recommend complementary products",
    items: [
      { title: "Custom", exp: "Build your own", }
    ]
  },
  {
    title: "Engagement",
    exp: "Assess customer churn risk and engage with them before potential churn triggers occur.",
    expShort: "Engage with customers before potential churn triggers occur.",
    items: [
      { title: "Custom", exp: "Build your own", }
    ]
  },
]


export const modelList = [
  {
    key: "o1-preview", // key to call the model in backend
    name: "o1 Preview",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728655654/pj_m_trial/eycos2gscd8jwgymylqb.png",
    exp: "OpenAI's advanced reasoning model",
    isNew: false
  },
  {
    key: "o1-mini",
    name: "o1 Mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728655654/pj_m_trial/eycos2gscd8jwgymylqb.png",
    exp: "OpenAI's efficient reasoning model",
    isNew: false
  },
  {
    key: "gpt-4o-mini",
    name: "GPT-4o mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728825677/pj_m_trial/cxn9dgvztxbojz4npqyw.png",
    exp: "OpenAI's most efficient language model",
    isNew: false
  },
  {
    key: "gpt-4o",
    name: "GPT-4o",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728825677/pj_m_trial/cxn9dgvztxbojz4npqyw.png",
    exp: "OpenAI's most advanced language model",
    isNew: false
  },
  // {
  //   key: "gpt-4",
  //   name: "GPT-4",
  //   logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728825677/pj_m_trial/cxn9dgvztxbojz4npqyw.png",
  //   exp: "OpenAI's legacy language model",
  //   isNew: false
  // },
  {
    key: "",
    name: "Claude 3.5 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's most advanced model",
    isNew: false
  },
  {
    key: "",
    name: "Claude 3 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's legacy efficient model",
    isNew: false
  },
  {
    key: "",
    name: "Claude 3 Opus",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's legacy advanced model",
    isNew: false
  },
  {
    key: "",
    name: "Claude 3 Haiku",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's fastest model",
    isNew: false
  },
  {
    key: "",
    name: "Llama 3.2 90B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's most advanced model",
    isNew: false
  },
  {
    key: "",
    name: "Llama 3.2 11B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's most efficient model",
    isNew: false
  },
  {
    key: "",
    name: "Llama 3.1 405B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's legacy advanced model",
    isNew: false
  },
  {
    key: "",
    name: "Llama 3.1 70B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's legacy efficient model",
    isNew: false
  },
  {
    key: "",
    name: "Mistral Large 2",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827861/pj_m_trial/qzlvoe5x2lvetx5htrf2.png",
    exp: "Mistral AI's most advanced model",
    isNew: false
  },
  {
    key: "gemini/gemini-2.0-flash-exp",
    name: "Gemini 2.0 Flash",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827948/pj_m_trial/bafblalqufyhzp60ommd.png",
    exp: "Google's latest and fastest model",
    isNew: false
  },
  {
    key: "gemini/gemini-1.5-pro",
    name: "Gemini 1.5 Pro",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827948/pj_m_trial/bafblalqufyhzp60ommd.png",
    exp: "Google's most advanced model",
    isNew: false
  },
  {
    key: "gemini/gemini-1.5-flash",
    name: "Gemini 1.5 Flash",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827948/pj_m_trial/bafblalqufyhzp60ommd.png",
    exp: "Google's latest and fastest model",
    isNew: false
  },
  {
    key: "",
    name: "Gemini 1.0 Pro",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827948/pj_m_trial/bafblalqufyhzp60ommd.png",
    exp: "Google's legacy model",
    isNew: false
  },
  {
    key: "",
    name: "DBRX - Instruct",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728828013/pj_m_trial/qixrwzgqpsosiecp029d.png",
    exp: "Databricks' latest model",
    isNew: false
  },
  {
    key: "",
    name: "Command R",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728828155/pj_m_trial/wjvulzytqpprhed92loz.png",
    exp: "Cohere's fastest model",
    isNew: false
  },
  {
    key: "",
    name: "Command R+",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728828155/pj_m_trial/wjvulzytqpprhed92loz.png",
    exp: "Cohere's advanced model",
    isNew: false,
  },
  // {
  //   name: "Solar 1 Mini",
  //   logo: "",
  //   exp: "Upstage's latest model",
  //  isNew: false,  
  // },
]

